<template>
  <a-modal v-model="visible" title="编辑表单" @cancel="clear" :width="900" :footer="null"
           :mask-closable="false">
    <formDetail v-if="visible" :id="id" @success="success"></formDetail>
  </a-modal>
</template>

<script>
// 无用文件，待删除
export default {
  name: 'editSignUpFormDetailLayer',
  props: ['id'],
  components: {
    formDetail: () => import('@/views/trainingCenter/offline/formDetailConfig'),
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    clear() {
      this.visible = false;
    },
    success() {
      this.$emit('success');
    },
  },
};
</script>

<style scoped>

</style>
